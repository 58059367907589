var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { staticClass: "pa-4", attrs: { flat: "" } },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab",
                { staticClass: "v-tab-element", attrs: { to: "#incomplete" } },
                [_vm._v(" Incomplete ")]
              ),
              _c(
                "v-tab",
                { staticClass: "v-tab-element", attrs: { to: "#complete" } },
                [_vm._v(" Completed ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { id: "incomplete" } },
            [
              _c("ComponentTaskListCompany", {
                attrs: { status: "Incomplete" },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { id: "complete" } },
            [_c("ComponentTaskListCompany", { attrs: { status: "Complete" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }